import * as type from "./type";
import { PAGES } from "../../constants/options";

const initialState = {
  lightTCPStatus: type.LIGHT_TCP_STATUS_TYPE.DISCONNECTED,
  activeSession: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_LIGHT_TCP_STATUS:
      return {
        ...state,
        lightTCPStatus: action.data,
      };
    case type.SET_LIGHT_ACTIVE_SESSION:
      return {
        ...state,
        activeSession: action.data,
      };
    default:
      return state;
  }
};

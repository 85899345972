import * as Types from "./type";

const actSetLightTCPStatus = (data) => ({
  type: Types.SET_LIGHT_TCP_STATUS,
  data,
});

export const reqActSetLightTCPStatus = (data) => (dispatch) => {
  return dispatch(actSetLightTCPStatus(data));
};

const actSetLightActiveSession = (data) => ({
  type: Types.SET_LIGHT_ACTIVE_SESSION,
  data,
});

export const reqSetLightActiveSession = (data) => (dispatch) => {
  return dispatch(actSetLightActiveSession(data));
};

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopNav from "../../components/top-nav";
import Loading from "../../components/loading";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useHistory } from "react-router-dom";
import FloorPlanGallery from "../../components/floor-plan-gallery";
import BottomNavigation from "../../components/bottom-navigation";
import UnitExplore from "../../components/unit-explore";
import TransportOption from "../../components/district-transport-option";
import Gallery from "../../components/gallery";
import RightPanel from "../../components/right-panel";
import socket from "../../helper/socket";
// import webSocket from "../../helper/websocket";
import { Quaternion, Vector3 } from "three";
import { isTouchDevice } from "../../helper/utils";

import {
  reqSetSelectedUnit,
  reqIsShowGallery,
  reqIsShowFloorplan,
  reqIsShowUnitDetail,
  reqSetIsShowFilter,
  reqFilterUnitLotSize,
  reqFilterUnitAvailability,
  reqIsShowVirtualTour,
  reqSetIsShowUnitList,
} from "../../reduxs/unit-explore/action";
import { reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";
import {
  reqSetIsExpandNav,
  reqSetPage,
  reqSetIsShowEndGuideTenantSession,
  reqSetActiveEndGuideTenantSessionId,
  reqSetIsTransparent,
  reqSetIsShowBookingAppointmentForm,
  reqSetIsPresentation,
} from "../../reduxs/home/action";
import {
  PAGES,
  LAYERS,
  TOP_NAV_TABS,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
  AVAILABILITY_OPTIONS,
} from "../../constants/options";
import { threePosition } from "../../helper/threeHeper";
import Login from "../../components/auth/Login";
import { reqSetIsShowReplayVideo } from "../../reduxs/precinct-explore/action";
import { setColor2 } from "../../helper/threeHeper";
import { reqSetIsShowGalleryModal } from "../../reduxs/district-future-detail/action";
import TransportOptionDistrict from "../../components/transport-options-district";
import EndGuideTenantSessionModal from "../../components/guide-sesions/end-guide-tenant-session-modal";
import {
  actIntroduction,
  setUnitView,
  setWhereView,
} from "../../reduxs/scene/action";
import { reqSetCustomerProfile } from "../../reduxs/user/action";
import VideoIntro from "../../components/video-intro";
import PostSessionPopup from "../../components/post-session-popup";
import HomeGallery from "../../components/home-gallery";
import VirtualModal from "../../components/home-gallery/virtual-modal";
import BookingAppointment from "../../components/booking-appointment";
import About from "../../components/about";
import LifeStyle from "../../components/life-style";
import Team from "../../components/team";
import SearchV2 from "../../components/search-v2";
import GalleryLanding from "../../components/gallery-landing";
import AmenitiesV2 from "../../components/amenitiesV2";
import Experience from "../../components/experience";
import Story from "../../components/story";
import LocationV3 from "../../components/locationV3";
import Vision from "../../components/vision";
import PageView from "../../components/page-view";
import Session from "../../components/session";
import { toast } from "react-toastify";
import { MAX, MIN } from "../../components/unit-explore/UnitFilter";
import {
  fetch3DSettings,
  fetchAssetsList,
  fetchHotspotsList,
  fetchPagesSettings,
} from '../../reduxs/scene/action';
import { reqActSetLightTCPStatus, reqSetLightActiveSession } from "../../reduxs/lightswarm/action";

const ReactUI = (props) => {
  const {
    setIsIntroduction,
    controls,
    refScene,
    setActiveObjectIds,
    resetActiveColorModel,
    activeObjectIds,
    isPresentation,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const lightTCPStatus = useSelector((state) => state.lightswarm.lightTCPStatus);
  const lightActiveSession = useSelector((state) => state.lightswarm.activeSession);

  const page = useSelector((state) => state.home.page);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const transportOptionDistricts = useSelector(
    (state) => state.home.transportOptionDistricts
  );
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const isShowVirtualModal = useSelector(
    (state) => state.gallery.isShowVirtualModal
  );
  const isShowBookingAppointmentForm = useSelector(
    (state) => state.home.isShowBookingAppointmentForm
  );
  const customer = useSelector((state) => state.user.customer);
  const authUser = useSelector((state) => state.user.data);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  useEffect(() => {
    if (selectedUnit) {
      setActiveObjectIds([selectedUnit["3d_asset"].id]);
    }
  }, [selectedUnit]);

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(
      (setting) => setting.name == page
    );
    if (settings.length == 0) {
      return <div />;
    }
    if (controls.current == null) {
      return <div />;
    }
    controls.current.currentPage = page;
    const setting = settings[0];
    if (!setting) {
      return <div />;
    }

    setting.active_objects != null &&
      setting.active_objects.length > 0 &&
      setActiveObjectIds(setting.active_objects);

    if (setting.camera && setting.camera_look_at_position && !selectedUnit && !isPresentation) {
      const camPosition = threePosition(setting.camera);
      const camLookAtPosition = threePosition(setting.camera_look_at_position);
      controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        () => { }
      );
    }

    if (
      Array.isArray(setting.show_layers) &&
      Array.isArray(setting.enable_layers)
    ) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(0);
      for (let i = 0; i < setting.show_layers.length; i += 1) {
        controls.current.showLayer(setting.show_layers[i]);
      }
      for (let i = 0; i < setting.enable_layers.length; i += 1) {
        controls.current.enableLayer(setting.enable_layers[i]);
      }
    }
    return <div />;
  });
  HandleSetActivePage.displayName = "HandleSetActivePage";

  const handleMoveCamera = (object, onCompleted = () => { }) => {
    if (!object.xyz_position) return;

    const position = threePosition(object.xyz_position);

    if (object.cam_position != null) {
      const camPosition = threePosition(object.cam_position);
      const camLookAtPosition =
        object.cam_focus_point_position != null
          ? threePosition(object.cam_focus_point_position)
          : position;
      controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        onCompleted
      );
    }
  };

  const handleClickCube = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetIsExpandNav(!isNavExpand));
    dispatch(reqSetIsTransparent(false));
  };

  const handleUnitClick = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT, {
        unit: item,
      });
      const lights = !!item["3d_asset"].light
        ? [{
          name: item["3d_asset"].light.name,
          group: item["3d_asset"].light.group,
          unitName: item.name
        }]
        : [];
      socket.emitLightUp(lights, lightActiveSession);
      const object = item?.["3d_asset"];
      if (!controls.current || !object) return;
      handleMoveCamera(object);
    }
    dispatch(reqIsShowUnitDetail(true));
    dispatch(reqSetIsShowFilter(false));
    dispatch(reqSetSelectedUnit(item.id));
  };

  const handleClickGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_GALLERY_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.GALLERY_LANDING_PAGE));
    analytics.track("View Gallery", {});
  };

  const handleClickLocation = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LOCATION_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.LOCATION_PAGE));
  };

  const handleClickEnvironment = () => {
    resetState();
    dispatch(reqSetPage(PAGES.ENVIRONMENT_PAGE));
  };

  const handleClickResidence = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_RESIDENCES_MENU);
    }
    // const event = new CustomEvent("UNIT_EXPLORER_PAGE", { detail: 'auto_available' });
    // document.dispatchEvent(event);
    dispatch(reqSetIsShowFilter(true));
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
    dispatch(reqFilterUnitAvailability([AVAILABILITY_OPTIONS[0]])); // set default to available
    resetState(true);
  };

  const handleClickVision = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_VISION_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.VISION_PAGE));
  };

  const handleClickView = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_VIEW_MENU);
    }
    resetState();
    dispatch(setWhereView("view"));
    dispatch(reqSetPage(PAGES.VIEW_PAGE));
    dispatch(setUnitView({}));
  };

  const handleClickExperience = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_EXPERIENCE_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.EXPERIENCE_PAGE));
  };

  const handleLifeStyle = () => {
    resetState();
    dispatch(reqSetPage(PAGES.LIFE_STYLE_PAGE));
  };
  const handleTeam = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_TEAM_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.TEAM_PAGE));
  };
  const handleSearch = () => {
    resetState();
    dispatch(reqSetPage(PAGES.SEARCH_PAGE));
  };

  const handleAmenities = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_AMENITIES_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.AMENITIES_PAGE));
  };
  const handleStory = () => {
    resetState();
    dispatch(reqSetPage(PAGES.STORY_PAGE));
  };

  const handleClickEndGuideTenantSession = (customerID) => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    resetState();

    dispatch(reqSetActiveEndGuideTenantSessionId(customerID));
    dispatch(reqSetIsShowEndGuideTenantSession(true));
    dispatch(reqSetPage(PAGES.END_GUIDE_TENANT_SESSION_PAGE));
  };

  const handleClickTenantEndSession = () => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push("/");
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  const handleClickTransportOptions = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const handleClickTransportOptionDistricts = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const resetState = (showSidebar) => {
    handleClickTransportOptions([], {});

    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;
    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach((id) => {
          let object = refScene.current.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        });
      });
    }

    resetActiveColorModel({});
    setActiveObjectIds([]);
    dispatch(reqSetSelectedUnit(""));
    dispatch(reqIsShowGallery(false));
    dispatch(reqIsShowVirtualTour(false));
    dispatch(
      reqFilterUnitLotSize({
        min: MIN,
        max: MAX,
      })
    );
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
    dispatch(reqIsShowUnitDetail(false));
    dispatch(reqSetIsShowBookingAppointmentForm(false));
    showSidebar && dispatch(reqSetIsShowFilter(true));
    showSidebar && dispatch(reqSetIsShowUnitList(true));
    showSidebar && dispatch(reqSetIsTransparent(false));
  };

  const renderTransportOptions = () => {
    if (pagesSettings == null) {
      return <div />;
    }

    const settings = pagesSettings.filter((setting) => setting.name == page);
    if (!settings.length) {
      return <div />;
    }

    const setting = settings[0];
    if (setting.features == null || !setting.features.transport_options) {
      return <div />;
    }

    return (
      <TransitionGroup>
        {
          <CSSTransition timeout={1000} classNames="fade-item">
            <TransportOption
              activeObjectIds={activeObjectIds}
              handleClickTransportOptions={handleClickTransportOptions}
            />
          </CSSTransition>
        }
      </TransitionGroup>
    );
  };

  const [isShowBottomNav, showBottomNav] = useState(true);


  const listenerSharedUIAction = ({ content }) => {
    handleApplyAction(content.action, content.data);
  };

  const listenerCameraAction = ({ content }) => {
    const POSITION_THRESHOLD = 0.15;

    if (controls.current != null) {
      const currentPos = controls.current?.object?.position;

      // get last and new position vectors
      let lastPosition = new Vector3(
        currentPos?.x,
        currentPos?.y,
        currentPos?.z,
      );
      let newPosition = new Vector3(
        content?.position?.x,
        content?.position?.y,
        content?.position?.z,
      );

      // get new quaternion
      const newQuaternion = new Quaternion(
        content?.quaternion?.x,
        content?.quaternion?.y,
        content?.quaternion?.z,
        content?.quaternion?.w,
      );

      controls.current.object.position.copy(newPosition);
      controls.current.object.quaternion.copy(newQuaternion);
      controls.current.object.zoom = content.zoom;
      controls?.current?.object?.updateProjectionMatrix();
    }
  };

  const establishSocket = (authUser) => {
    if (!authUser || socket.socket.connected) return;

    // webSocket.connect(authUser);
    socket.connect(authUser);

    socket.on(WEBSOCKET_CHANNEL.LIGHT_TCP_CONNECTED, ({ status, message, to, error }) => {
      dispatch(reqActSetLightTCPStatus(status));
    })

    
    socket.on(WEBSOCKET_CHANNEL.LIGHT_TCP_CHECK_STATUS_ACK, ({ status }) => {
      dispatch(reqActSetLightTCPStatus(status));
    })

    
    socket.on(WEBSOCKET_CHANNEL.LIGHT_CHECK_ACTIVE_SESSION_ACK, ({ activeSession }) => {
      dispatch(reqSetLightActiveSession(activeSession));
    })

    socket.on(WEBSOCKET_CHANNEL.LIGHT_SESSION_CHANGED, ({ oldSession, newSession, to }) => {
      dispatch(reqSetLightActiveSession(newSession));
    });

    socket.emit(WEBSOCKET_CHANNEL.LIGHT_TCP_CHECK_STATUS);
    socket.emit(WEBSOCKET_CHANNEL.LIGHT_CHECK_ACTIVE_SESSION);


    if (authUser && authUser.isLightControl) {
      socket.on(WEBSOCKET_CHANNEL.LIGHT_CONNECT, (msg) => {
        console.log(msg);
      });

    
      // socket.on(WEBSOCKET_CHANNEL.LIGHT_DISCONNECT, (msg) => {
      //   console.log("Lost connection to model", msg);
      // });
      socket.on(WEBSOCKET_CHANNEL.LIGHT_ERROR, ({ errors }) => {
        const lightSession = localStorage.getItem('lightSession');
        const isActiveSession = lightActiveSession && lightSession && lightActiveSession === lightSession;
        if (!isActiveSession) return;

        toast.error(`No corresponding light to unit: ${errors.join(", ")}`, {
          toastId: `${authUser.id}-model-error`
        })
      });

    }

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);

      socket.on(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, listenerCameraAction);
    }
  };

  useEffect(() => {
    isPresentation && dispatch(reqSetIsPresentation(true));
  }, []);

  useEffect(() => {
    establishSocket(authUser);

    const root = window.document.getElementById("root");

    const handleMouseMove = _.debounce((e) => {
      if (!socket.socket.connected) {
        establishSocket(authUser);
      }
    }, 150);

    const touchDevice = isTouchDevice();

    if (touchDevice) {
      root.addEventListener("touchstart", handleMouseMove);
    } else {
      root.addEventListener("mouseover", handleMouseMove);
    }

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }

      if (touchDevice) {
        root.removeEventListener("touchstart", handleMouseMove);
      } else {
        root.removeEventListener("mouseover", handleMouseMove);
      }

      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      socket.off(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, listenerCameraAction);
    };
  }, [authUser?.id]);

  const handleApplyAction = (action, data) => {
    if (action == ACTION_NAME.CLICK_LOCATION_MENU) {
      return handleClickLocation();
    }

    if (action == ACTION_NAME.CLICK_GALLERY_MENU) {
      return handleClickGallery();
    }

    if (action == ACTION_NAME.CLICK_CUBE_MENU) {
      return handleClickCube();
    }

    if (action == ACTION_NAME.CLICK_AMENITIES_MENU) {
      return handleAmenities();
    }

    if (action == ACTION_NAME.CLICK_TEAM_MENU) {
      return handleTeam();
    }

    if (action == ACTION_NAME.CLICK_EXPERIENCE_MENU) {
      return handleClickExperience();
    }

    if (action == ACTION_NAME.CLICK_VIEW_MENU) {
      return handleClickView();
    }

    if (action == ACTION_NAME.CLICK_VISION_MENU) {
      return handleClickVision();
    }

    if (action === ACTION_NAME.CLICK_RESIDENCES_MENU) {
      return handleClickResidence();
    }

    if (action === ACTION_NAME.CLICK_UNIT) {
      return handleUnitClick(data?.unit);
    }

    if (action === ACTION_NAME.CLICK_SEARCH_MENU) {
      return handleSearch();
    }

    if (action === ACTION_NAME.CLOSE_PANO_VIEWER) {
      return handleClosePanoViewer();
    }

    if (action === ACTION_NAME.EXPLORE_SHOW_FILTER) {
      let showFilter = data?.showFilter ?? false;
      dispatch(reqSetIsShowFilter(showFilter));
      return;
    }

    if (action === ACTION_NAME.TOAST_MESSAGE) {
      toast[data.type](data.message, {
        toastId: "socket-toast",
      });
      return;
    }
  };

  const renderTransportOptionDistricts = () => {
    if (pagesSettings == null) {
      return <div />;
    }

    const settings = pagesSettings.filter((setting) => setting.name == page);
    if (!settings.length) {
      return <div />;
    }

    const setting = settings[0];
    if (
      setting.features == null ||
      !setting.features.transport_option_districts
    ) {
      return <div />;
    }

    return (
      <TransitionGroup>
        {
          <CSSTransition timeout={1000} classNames="fade-item">
            <TransportOptionDistrict
              activeObjectIds={activeObjectIds}
              refScene={refScene}
              handleClickTransportOptionDistricts={
                handleClickTransportOptionDistricts
              }
            />
          </CSSTransition>
        }
      </TransitionGroup>
    );
  };

  // const onReplayVideo = () => {
  //   dispatch(reqSetIsShowReplayVideo(true));
  // };

  const [init, setInit] = useState(false);
  useEffect(() => {
    if (init || page === PAGES.SESSION_PAGE) {
      return;
    }

    setInit(true);
    dispatch(fetch3DSettings());
    dispatch(fetchAssetsList());
    dispatch(fetchHotspotsList());
    dispatch(fetchPagesSettings());
  }, [init, page])

  const renderLightConnectButton = () => {
    if (!authUser || !authUser.isLightControl) return;
    
    if (page !== PAGES.LANDING_PAGE && page !== PAGES.UNIT_EXPLORER_PAGE) return;

    const lightSession = localStorage.getItem('lightSession');
    const isActiveSession = lightActiveSession && lightSession && lightActiveSession === lightSession;
    const isModelOnline = lightTCPStatus === 'connected';

    let btnText = '';
    let btnDisabled = false;

    if (isActiveSession && isModelOnline) {
      btnText = 'Connected Model';
      btnDisabled = true;
    }

    if (!isActiveSession && isModelOnline) {
      btnText = 'Connect to Model';
    }

    if (isActiveSession && !isModelOnline) {
      btnText = 'Model Not Online';
      btnDisabled = true;
    }

    if (!isActiveSession && !isModelOnline) {
      btnText = 'Connect to Model';
    }

    return (
      <button
        disabled={btnDisabled}
        onClick={() => {
          // Get existing lightSession from localStorage or create a new one
          if (!lightSession) {
            lightSession = window.crypto.randomUUID();
            localStorage.setItem('lightSession', lightSession);
          }
          socket.emit(WEBSOCKET_CHANNEL.LIGHT_FORCE_NEW_SESSION, {
            lightSession,
            to: authUser?.id,
          }, (error) => {
            // Add callback to check if emission was successful
            if (error) {
              console.error('Emission error:', error);
            } else {
              console.log('Event emitted successfully');
            }
          })
        }}

        style={{
          position: "absolute",
          color: "#8F8F8F",
          top: "100px",
          right: "26px",
          border: "none",
          background: "none",
        }}
      >
        {btnText}
      </button>)
  }

  return (
    <>
      {TOP_NAV_TABS?.filter((it) => it != PAGES.RESIDENCES_PAGE)?.includes(
        page
      ) ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "white",
          }}
        />
      ) : null}
      <HandleSetActivePage pagesSettings={pagesSettings} page={page} />
      <TransitionGroup>
        {page == PAGES.END_GUIDE_TENANT_SESSION_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <EndGuideTenantSessionModal resetState={resetState} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TopNav
        isPresentation={isPresentation}
        isShow={page !== PAGES.ONBOARD_PAGE}
        isLoading={isLoading}
        handleClickCube={handleClickCube}
        handleClickGallery={handleClickGallery}
        handleClickLocation={handleClickLocation}
        handleClickEnvironment={handleClickEnvironment}
        handleClickExperience={handleClickExperience}
        handleLifeStyle={handleLifeStyle}
        handleTeam={handleTeam}
        handleSearch={handleSearch}
        handleAmenities={handleAmenities}
        handleStory={handleStory}
        handleClickResidence={handleClickResidence}
        handleClickVision={handleClickVision}
        handleClickView={handleClickView}
      />
      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
          isPresentation={isPresentation}
        />
      )}
      <TransitionGroup>
        {page == PAGES.LOCATION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <LocationV3 isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowFloorplan && (
          <CSSTransition
            in={isShowFloorplan}
            timeout={1000}
            classNames="fade-item"
            unmountOnExit
          >
            <FloorPlanGallery isPresentation={FloorPlanGallery} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowBottomNav && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <BottomNavigation
              handleClickEndGuideTenantSession={
                handleClickEndGuideTenantSession
              }
              handleClickTenantEndSession={handleClickTenantEndSession}
              isPresentation={isPresentation}
            />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {
          (page === PAGES.UNIT_EXPLORER_PAGE || page === PAGES.LANDING_PAGE) &&
          <CSSTransition
            in={!!(page === PAGES.UNIT_EXPLORER_PAGE || page === PAGES.LANDING_PAGE)}
            timeout={1000}
            classNames="fade-left"
            unmountOnExit
          >
            <UnitExplore
              handleUnitClick={handleUnitClick}
              setActiveObjectIds={setActiveObjectIds}
              handleAreaClick={(area) => { }}
              controls={controls}
              refScene={refScene}
              isPresentation={isPresentation}
            />
          </CSSTransition>
        }
      </TransitionGroup>

      {renderTransportOptions()}
      {renderTransportOptionDistricts()}

      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <Gallery isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <RightPanel
        page={page}
        handleClickTransportOptions={handleClickTransportOptions}
        isPresentation={isPresentation}
      />
      <Login />
      <TransitionGroup>
        {isShowReplayVideo && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <VideoIntro />
          </CSSTransition>
        )}
      </TransitionGroup>
      <PostSessionPopup isPresentation={isPresentation} />
      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <HomeGallery />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowVirtualModal && (
          <CSSTransition timeout={200} classNames="fade-item">
            <VirtualModal />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowBookingAppointmentForm && (
          <CSSTransition timeout={200} classNames="fade-item">
            <BookingAppointment />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.ABOUT_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <About />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.LIFE_STYLE_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <LifeStyle />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.TEAM_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Team isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.SEARCH_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <SearchV2 />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.VIEW_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <PageView
              controls={controls}
              handleUnitClick={handleUnitClick}
              isPresentation={isPresentation}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.GALLERY_LANDING_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <GalleryLanding isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.AMENITIES_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <AmenitiesV2
              handleBackBuilding={handleClickCube}
              isPresentation={isPresentation}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.EXPERIENCE_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Experience isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.STORY_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Story />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.VISION_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Vision isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.SESSION_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Session isPresentation={isPresentation} resetState={resetState} resetActiveColorModel={resetActiveColorModel} setActiveObjectIds={setActiveObjectIds} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <CSSTransition
        in={page !== PAGES.ONBOARD_PAGE}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <div>
          {page === PAGES.EXPLORE_BUILDING_PAGE && (
            <div className="btn-center">
              <button onClick={handleAmenities} className="replay-video">
                EXPLORE MORE OF THE ST. REGIS AMENITIES
                <img src="./images/icons/arrow-left.svg" />
              </button>
            </div>
          )}
        </div>
      </CSSTransition>
      {/* <CSSTransition
        in={page !== PAGES.ONBOARD_PAGE}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <div>
          {page === PAGES.LANDING_PAGE && (
            <div className="btn-center">
              <button onClick={onReplayVideo} className="replay-video">
                <img src="./images/icons/arrow-left.svg" className="rotate" alt="alt-replay-video" />
                Replay Video
              </button>
            </div>
          )}
        </div>
      </CSSTransition> */}
      {renderLightConnectButton()}
    </>
  );
};

export default ReactUI;

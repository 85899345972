import React, { useState, useEffect } from "react";
import iconPlay from "../../../assets/images/ellipse.svg";
import virtual1 from "../../../assets/images/virtual1.webp";
import virtual2 from "../../../assets/images/virtual2.webp";
import virtual3 from "../../../assets/images/virtual3.webp";
import virtual4 from "../../../assets/images/virtual4.webp";
import virtual5 from "../../../assets/images/virtual5.webp";
import virtual6 from "../../../assets/images/virtual6.webp";
import thumbnail1 from "../../../assets/images/virtual-tours-thumbnail-1.webp";
import thumbnail2 from "../../../assets/images/virtual-tours-thumbnail-2.webp";
import "../../home-gallery/index.scss";
import "./index.scss";
import { AwModal } from "../../aw-modal/aw-modal";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../../constants/options";
import socket from "../../../helper/socket";
import { Equirectangular } from "./equirectangular";
import { IconArrowThin } from "../../svgs/icons";

export const virtualTours = [
    {
        url: [virtual1, virtual3, virtual2, virtual4, virtual6, virtual5],
        thumbnail: thumbnail1,
        name: 'Residence 19A',
        description: 'VIRTUAL TOUR'
    },
    {
        url: [virtual2, virtual4, virtual1, virtual6, virtual3, virtual5],
        thumbnail: thumbnail2,
        name: 'Residence LPHC',
        description: 'VIRTUAL TOUR'
    }
  ]

export const HotspotWrapper = ({urls, setUrl}) => {
    return <ul style={{ position: 'absolute', zIndex: 1000, top: 300 }}>
        {
            urls?.map((url, i) => (
                <div key={i} onClick={() => {
                    setUrl(url)
                }} className="hotspot-wrapper">{`Hotspot ${i + 1}`}</div>
            ))
        }
    </ul>
}

const VirtualTours = ({ isInfinity, isPresentation }) => {
  const [isShowVirtual, setShowVirtual] = useState(false);
  const [urls, setUrls] = useState([]);
  const[name, setName] = useState("");
  const [urlVirtual, setUrlVirtual] = useState("");
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PLAY_FILM_GALLERY_PAGE) {
          handlePlayVideo(content?.data?.url);
        }
        if (content.action === ACTION_NAME.STOP_FILM_GALLERY_PAGE) {
            onStopVirtual();
        }
      });
    }
  }, []);
  const handleShowVirtual = (url) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_FILM_GALLERY_PAGE, {
        url,
      });
    }
    setUrls(url);
    setUrlVirtual(url[0]);
    setShowVirtual(true);
  };
  const onStopVirtual = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_FILM_GALLERY_PAGE);
    }
    setShowVirtual(false);
    setUrlVirtual("");
  };

  return (
    <div className="films-content">
      {virtualTours.map((i, idx) => {
        return (
          <div
            key={`films-${idx}`}
            // data-fancybox="gallery"
            className={`card-films ${isInfinity ? "infinity" : ""}`}
            style={{
              backgroundImage: `url(${i.thumbnail}`,
            }}
            onClick={() => {handleShowVirtual(i.url);setName(i.name)}}
          >
            <div className="card-films-title">{`${i.name}`}</div>
            <div className="card-films-sub">{i.description}</div>
            <img src={iconPlay} alt="play-icon" />
          </div>
        )
      })}
      {isShowVirtual && (
        // <AwModal show={isShowVirtual} onClose={onStopVirtual}>
        //     <HotspotWrapper setUrl={setUrlVirtual} urls={urls}/>
        //     <Equirectangular url={urlVirtual}/>
        // </AwModal>
        <div className="wrap-floor-plan-gallery">
          <AwModal show={isShowVirtual}>
            <div className="wrap-nav">
              <p className="unit-name">{name}</p>
              <p className="close-button" onClick={onStopVirtual}>
                <IconArrowThin style={{ width: 31, marginRight: 4 }} fill={"#B2B2B2"} />
                CLOSE VIRTUAL TOUR
              </p>
            </div>
            <HotspotWrapper setUrl={setUrlVirtual} urls={urls}/>
            <Equirectangular url={urlVirtual}/>
            <span className="close-button-down" onClick={onStopVirtual}>CLOSE VIRTUAL TOUR</span>
          </AwModal>
        </div>
      )}
    </div>
  );
};
export default VirtualTours;

import { io } from "socket.io-client";
import config from "../config/index";
import { WEBSOCKET_CHANNEL } from "../constants/options";
import { toast } from "react-toastify";

class SocketIo {
  constructor() {
    this.socket = io(config.SOCKET_URL, {
      autoConnect: false,
      rejectUnauthorized: false,
    });
  }

  connect(authUser) {
    this.authUser = authUser;

    // Get existing lightSession from localStorage or create a new one
    let lightSession = localStorage.getItem('lightSession');
    if (!lightSession) {
      lightSession = window.crypto.randomUUID();
      localStorage.setItem('lightSession', lightSession);
    }

    this.socket.auth = {
      userId: authUser?.id,
      lightSession,
    }

    return this.socket.connect();
  }

  disconnect() {
    return this.socket.disconnect();
  }

  emitUIActionEvent(actionName, data) {
    return this.socket.emit(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, {
      content: {
        action: actionName,
        data: data,
      },
      to: this.authUser?.id || "cp87pvd1ec",
      from: this.authUser?.id || "cp87pvd1ec",
    });
  }

  // lights: light number to *TURN ON*
  // turnAllOff, turnAllOn: turn on or off all light (in this case, value of "lights" doesn't matter)
  emitLightUp(lights, lightActiveSession = '', options = { turnAllOff: false, turnAllOn: false }) {
    if (!this.authUser || !this.authUser.isLightControl) return;
    const lightSession = localStorage.getItem('lightSession');
    if (!lightSession || !lightActiveSession || lightSession !== lightActiveSession) return;
    
    return this.socket.emit(WEBSOCKET_CHANNEL.LIGHT_UP, { lights, lightSession, from: this.authUser?.id, ...options, })
  }

  emit(channel, data) {
    return this.socket.emit(channel, data);
  }

  on(channel, callback) {
    return this.socket.on(channel, callback);
  }

  off(channel, callback) {
    return this.socket.off(channel, callback);
  }

  shareMedia(data) {
    if (!this.authUser.presentation) return;

    return this.socket.emit(WEBSOCKET_CHANNEL.SHARE_MEDIA, data);
  }

}

const socket = new SocketIo();

export default socket;
